<template>
    <div class="employment-type">
        <div class="title" v-html="`${$translate('employment_type'.toUpperCase())}`" />
        <VerticalOptions @select="onClickItem" :optionItems.sync="employmentType" />
    </div>
</template>

<script>
import VerticalOptions from './VerticalOptions.vue'
export default {
    name: 'EmploymentType',
    props: {
        edit: Object,
        stage: Number,
    },
    components: {
        VerticalOptions,
    },
    computed: {
        profile() {
            return this.$store.getters.profile || {}
        },
    },
    data: () => ({
        selectedItem: null,
        employmentType: null,
    }),
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.employmentType = this.$profile.employmentType
            this.employmentType = Object.keys(this.employmentType).map(key => {
                const item = this.employmentType[key]
                if (item.id === this.profile.company[0].employment_type) {
                    item.$$selected = true
                } else {
                    item.$$selected = false
                }
                return item
            })
        },
        onClickItem(val) {
            this.selectedItem = val
            this.$emit('close')
        },
        onSave(key) {
            let tempCompany = []
            if (this.profile.company.length) {
                tempCompany = this.profile.company
                tempCompany[0][key] = this.selectedItem.id
            } else {
                tempCompany.push({
                    [key]: Number(this.selectedItem.id),
                })
            }
            this.$set(this.profile, 'company', tempCompany)
        },
    },
}
</script>

<style scoped lang="scss">
.title {
    font-size: 18px;
    text-align: center;
    @include f-medium;
    height: 27px;
    padding: 4px;
    margin: 20px 32px 0px 32px;
    color: #000000;
}
</style>
